import React from "react";
import { graphql } from "gatsby";

import Jobs from "../components/Jobs";

import metaData from "../languages/en/metaData";
import dictionary from "../languages/en/jobs";

const PageJobsEnQuery = graphql`
  query PageJobsEn($type: String) {
    allMarkdownRemark(
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateType: { eq: $type } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date
            title
            location
            requirement
            links {
              name
              url
            }
          }
        }
      }
    }
  }
`;

const PageJobsEn = (props) => {
  const { edges } = props.data.allMarkdownRemark;
  return (
    <Jobs lang="en" metaData={metaData} dictionary={dictionary} data={edges} />
  );
};

export default PageJobsEn;
export { PageJobsEnQuery };
